var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landlords-questionnaire",attrs:{"id":"landlords-form"}},[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"landlords-questionnaire__title title"},[_vm._v(" "+_vm._s(_vm.$t("realEstateOffers"))+" ")]),_c('validation-observer',{ref:"formObserver"},[_c('form',{ref:"fullVacancyForm",staticClass:"form"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"form__input",attrs:{"type":"text","placeholder":_vm.$t('landlordCity'),"name":"city"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"form__input",attrs:{"type":"text","placeholder":_vm.$t('landlordAddress'),"name":"address"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}})]}}])}),(_vm.objectTypeUnselected)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("notSelected"))+" ")]):_vm._e(),_c('app-select',{attrs:{"placeholder":_vm.$t('landlordType'),"dropdown":_vm.$t('landlordTypes'),"field":"name"},model:{value:(_vm.objectType),callback:function ($$v) {_vm.objectType=$$v},expression:"objectType"}}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactPerson),expression:"contactPerson"}],staticClass:"form__input",attrs:{"type":"text","placeholder":_vm.$t('landlordFio'),"name":"fullname"},domProps:{"value":(_vm.contactPerson)},on:{"input":function($event){if($event.target.composing){ return; }_vm.contactPerson=$event.target.value}}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"form__input",attrs:{"type":"text","placeholder":_vm.$t('landlordPhone'),"name":"phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})]}}])}),_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "email" ? _vm.$t("emailEnteredIncorrectly") : errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form__input",attrs:{"type":"email","placeholder":_vm.$t('landlordMail'),"name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]}}])}),_c('button',{staticClass:"btn-form",on:{"click":function($event){$event.preventDefault();return _vm.sendForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("submit"))+" ")])],1)]),_c('div',{staticClass:"landlords-questionnaire__form-info"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("landlordFormText"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("landlordFormText1"))+" ")])])],1),(_vm.isShowModalReceived)?_c('app-modal-received',{attrs:{"title":"thankRequest","text":"definitelyTake","image":"appeal.png","btn":"good"},on:{"close":_vm.closeModalReceived}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }