<template>
  <div class="landlords-questionnaire" id="landlords-form">
    <div class="container">
      <h3 class="landlords-questionnaire__title title">
        {{ $t("realEstateOffers") }}
      </h3>
      <validation-observer ref="formObserver">
        <form ref="fullVacancyForm" class="form">
          <validation-provider v-slot="{ errors }" rules="required">
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span>
            <input
              v-model="city"
              type="text"
              :placeholder="$t('landlordCity')"
              class="form__input"
              name="city"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="required">
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span>
            <input
              v-model="address"
              type="text"
              :placeholder="$t('landlordAddress')"
              class="form__input"
              name="address"
            />
          </validation-provider>

          <p v-if="objectTypeUnselected" style="color: red">
            {{ $t("notSelected") }}
          </p>
          <app-select
            v-model="objectType"
            :placeholder="$t('landlordType')"
            :dropdown="$t('landlordTypes')"
            field="name"
          />

          <validation-provider v-slot="{ errors }" rules="required">
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span>
            <input
              v-model="contactPerson"
              type="text"
              :placeholder="$t('landlordFio')"
              class="form__input"
              name="fullname"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="required">
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span>
            <input
              v-model="phone"
              type="text"
              :placeholder="$t('landlordPhone')"
              class="form__input"
              name="phone"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="email">
            <span style="color: red">{{
              errors[0] === "email" ? $t("emailEnteredIncorrectly") : errors[0]
            }}</span>
            <input
              v-model="email"
              type="email"
              :placeholder="$t('landlordMail')"
              class="form__input"
              name="email"
            />
          </validation-provider>

          <button class="btn-form" @click.prevent="sendForm">
            {{ $t("submit") }}
          </button>
        </form>
      </validation-observer>
      <div class="landlords-questionnaire__form-info">
        <p>
          {{ $t("landlordFormText") }}
        </p>
        <p>
          {{ $t("landlordFormText1") }}
        </p>
      </div>
    </div>
    <app-modal-received
      v-if="isShowModalReceived"
      @close="closeModalReceived"
      title="thankRequest"
      text="definitelyTake"
      image="appeal.png"
      btn="good"
    />
  </div>
</template>

<script>
import { sendLandlordsQuestionnaire } from "@/api/landlords-questionnaire";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "LandlordsQuestionnaire",
  components: {
    ValidationProvider,
    ValidationObserver,
    AppSelect: () => import("@/components/common/AppSelect"),
    AppModalReceived: () =>
      import("@/components/pages/modal/AppModalReceived.vue"),
  },
  data() {
    return {
      active: false,

      // form variables
      objectType: null,
      objectTypeUnselected: false,

      city: "",
      address: "",
      contactPerson: "",
      phone: "",
      email: "",
      isShowModalReceived: false,
    };
  },
  watch: {
    objectType: {
      handler() {
        this.objectTypeUnselected = false;
      },
    },
  },
  methods: {
    toggleClass() {
      this.active = !this.active;
    },
    sendForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (!this.objectType) {
          this.objectTypeUnselected = true;
        }
        if (success && !this.objectTypeUnselected) {
          this.questionnaireForm = {
            data: {
              city: this.city,
              address: this.address,
              type: this.objectType.type,
              contact_person: this.contactPerson,
              phone: this.phone,
              email: this.email,
              createdAt: "2022-06-07T14:25:02.186Z",
              updatedAt: "2022-06-07T14:25:02.186Z",
              publishedAt: "2022-06-07T14:25:02.186Z",
              createdBy: "string or id",
              updatedBy: "string or id",
            },
          };
          sendLandlordsQuestionnaire(this.questionnaireForm).then((res) => {
            if (res.status === 200) {
              this.isShowModalReceived = true;
            }
          });
        } else {
          document
            .querySelector("#landlords-form")
            .scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    closeModalReceived() {
      this.isShowModalReceived = false;
      location.reload();
    },
  },
};
</script>

<style scoped>
#landlords-form {
  margin-top: -100px;
  padding-top: 100px;
}
</style>
